
import {computed, defineComponent, onMounted, ref, unref as v, watch, watchEffect} from 'vue'
import {useWorkersStore} from "@/store/workers/WorkersStore";
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";

export default defineComponent({
  name: "WorkersDropdown",
  components: {MSimpleDropdownOption, MSimpleDropdownButton, MSimpleDropdown},
  emits: {
    onWorker: (uid: unknown) => typeof uid === "string" || true
  },
  props: {
    clinicUID: {
      type: String,
      required: false
    },
    resetSelectedToggle: {
      type: Boolean,
      required: false
    },
    showSelectAll: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const workersStore = useWorkersStore()
    const workersState = workersStore.getState()
    const allWorkers = computed(() => workersState.workers)
    const workers = computed(() => props.clinicUID ? allWorkers.value.filter(worker => worker.clinicUID === props.clinicUID) : allWorkers.value)
    const selected = ref<string | undefined>(undefined)

    const selectedWorker = computed(() => v(workers).find(({uid}) => uid === v(selected)))
    const isDropdown = ref(false)
    const dropDownId = 'workers-dd'
    const buttonText = computed(() => selectedWorker.value?.name || '')
    const title = computed(() => selectedWorker.value ? 'Töötaja' : 'Palun vali töötaja')

    const setIsDropdown = (newValue: boolean) => isDropdown.value = newValue
    onMounted(async () => {
      await workersStore.setWorkers(false)
      window.addEventListener("click", (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement
          if (!target.closest(`#${dropDownId}`)) setIsDropdown(false)
        }
      })
    })
    const selectOption = (uid?: string, force = false) => {
      selected.value = uid
      setIsDropdown(false)
      if (uid || force) emit('onWorker', selected.value)
    }
    const selectAll = () => selectOption(undefined, true)

    watch(() => props.clinicUID, () => selectOption(undefined))
    watch(() => props.resetSelectedToggle, () => selectOption(undefined, true))


    return {isDropdown, dropDownId, setIsDropdown, workers, selected, buttonText, selectOption, selectAll, title}
  }
})
