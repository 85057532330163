
import {computed, defineComponent, onBeforeMount, onMounted, ref, unref as v, watch, watchEffect} from 'vue'
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";

export default defineComponent({
  name: "CabinetsDropdown",
  components: {MSimpleDropdown, MSimpleDropdownOption, MSimpleDropdownButton},
  emits: {
    onCabinet: (uid: unknown) => typeof uid === "string" || true
  },
  props: {
    showSelected: {
      type: Boolean,
      default: true
    },
    clinicUID: {
      type: String,
      required: false
    },
    resetSelectedToggle: {
      type: Boolean,
      required: false
    },
    showSelectAll: {
      type: Boolean,
      default: false
    },
    licenceNo: {
      type: String,
      required: false
    }
  },
  setup(props, {emit}) {

    const cabinetsStore = useCabinetsStore()
    const cabinetsState = cabinetsStore.getState()
    const allCabinets = computed(() => cabinetsState.cabinets)
    const clinicCabinets = computed(() => props.clinicUID ? allCabinets.value.filter(cabinet => cabinet.clinicUID === props.clinicUID) : allCabinets.value)
    const cabinets = computed(() => props.licenceNo ? clinicCabinets.value.filter(cabinet => cabinet.licenceNo === props.licenceNo) : clinicCabinets.value)
    const selected = ref<string | undefined>(undefined)
    const selectedCabinet = computed(() => cabinets.value.find(({uid}) => uid === selected.value))
    const isDropdown = ref(false)
    const dropDownId = 'cabinets-dd'
    const buttonText = computed(() => selectedCabinet.value?.name || '')
    const title = computed(() => selectedCabinet.value ? 'Kabinet' : 'Palun vali kabinet')

    const setIsDropdown = (newValue: boolean) => isDropdown.value = newValue
    onBeforeMount(async () => {
      await cabinetsStore.setCabinets(false)
      window.addEventListener("click", (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement
          if (!target.closest(`#${dropDownId}`)) setIsDropdown(false)
        }
      })
    })

    const selectOption = (uid?: string, force = false) => {
      selected.value = uid
      setIsDropdown(false)
      if (uid || force) emit("onCabinet", selected.value)
    }

    const selectAll = () => selectOption(undefined, true)

    watch(() => props.clinicUID, () => selectOption(undefined))
    watch(() => props.resetSelectedToggle, () => selectOption(undefined, true))
    watch(() => props.licenceNo, () => {
      if (!props.licenceNo) return
      if (!selected.value) return
      if (selectedCabinet.value?.licenceNo === props.licenceNo) return
      selectOption(undefined, true)
    })
    return {isDropdown, dropDownId, setIsDropdown, cabinets, selected, title, selectOption, selectAll, buttonText}
  }
})
