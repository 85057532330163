
import {defineComponent, PropType, ref} from 'vue'
import {PdfData} from "@/components/clinics/reports/pdfgenerator/pdf-parts/example-data";
import ClinicReportsPDFModal from "@/components/clinics/reports/ClinicReportsPDFModal.vue";


export default defineComponent({
  name: "ClinicsReportsControls",
  components: {ClinicReportsPDFModal},
  props: {
    pdfData: {
      type: Object as PropType<PdfData>,
      required: true
    }
  },
  setup() {
    const isPDFModal = ref(false)


    return {isPDFModal}
  }
})
