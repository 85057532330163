
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    isDetailView: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {}
  }
})
