import {PdfData} from "@/components/clinics/reports/pdfgenerator/pdf-parts/example-data";

export const pdfHeader = (pdfData: PdfData) => {
    const columns = []
    if (pdfData.logo) {
        columns.push({
            margin: [20, 10, 0, 0], // [left, top, right, bottom]
            image: pdfData.logo,
            width: 100,
        })
    }
    const stack = []
    if (pdfData.licenseNumber) {
        stack.push({
            text: `Kiirgustegevusloa nr: ${pdfData.licenseNumber}`,
            alignment: "center",
            fontSize: 14,
        })
    }
    return {
        columns: [
            ...columns,
            {
                margin: [0, 10, 0, 0],
                stack: [
                    {
                        text: `Kiirgustöötaja kutsekiirituse dooside aruanne ${pdfData.reportYear}`,
                        alignment: "center",
                        fontSize: 14,
                    },
                    ...stack
                ],
            },
        ],
    };
};
