import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicRepostsListHeader = _resolveComponent("ClinicRepostsListHeader")!
  const _component_ClinicReportsListBody = _resolveComponent("ClinicReportsListBody")!
  const _component_BaseTableWrapper = _resolveComponent("BaseTableWrapper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseTableWrapper, null, {
      default: _withCtx(() => [
        _createVNode(_component_ClinicRepostsListHeader, { isDetailView: _ctx.isDetailView }, null, 8, ["isDetailView"]),
        _createVNode(_component_ClinicReportsListBody, {
          "filter-params": _ctx.filterParams,
          isDetailView: _ctx.isDetailView,
          onOnWorkers: _ctx.onWorkers
        }, null, 8, ["filter-params", "isDetailView", "onOnWorkers"])
      ]),
      _: 1
    })
  ]))
}