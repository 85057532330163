import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cursor-pointer hover:bg-gray-100 transform transition duration-100 ease-in-out" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.worker.name), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.worker.personalNo), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.clinic.name), 1)
  ]))
}