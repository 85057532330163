import {PdfData, TableData} from "./example-data";

export interface Layout {
    hLineWidth?: () => number;
    vLineWidth?: () => number;
    hLineColor?: () => string;
    vLineColor?: () => string;
}

export interface Row {
    text?: string | number;
    border?: boolean[];
    alignment?: string;
    italics?: boolean;
    colspan?: number;
}

export interface Table {
    headerRows?: number;
    widths?: number[] | string[];
    body?: Row[] | unknown;
}

export interface Stack {
    margin?: number[] | string[];
    image?: string;
    width?: number | string;
    columns?: Column[];
    text?: string;
    fontSize?: number;
    layout?: Layout;
    table?: Table;
    pageBreak?: string;
    alignment?: string;
    italics?: boolean;
}

export interface Column {
    margin?: number[] | string[];
    svg?: string;
    width?: number | string;
    text?: string;
    fontSize?: number;
    layout?: Layout;
    table?: Table;
    stack?: Stack[];
}

export interface PdfContent {
    stack?: Stack[];
}

export const pdfContent = (pdfData: PdfData): PdfContent[] => {
    const fontSize = 9;

    // TABLE --------------------------------------------------------------
    // define table based on tableType
    const tableWidth = [];

    const tableHeader = [];

    const tableBodyRows: Row[][] = [];

    // define table width
    tableWidth.push(...["5%", "20%", "20%", "20%", "20%", "15%"]);


    // define table header
    tableHeader.push([
        {text: "Nr", alignment: "center"},
        {text: "Kiirgustegevusloa omaja", alignment: "center"},
        {text: "Kiirgustöötaja ees-js perekonnanimi", alignment: "center"},
        {text: "Isikukood", alignment: "center"},
        {text: "Kiirgustöötaja kategooria", alignment: "center"},
        {text: "Aastane kutsekiirituse doos (µSv)", alignment: "center"},
    ]);

    // define table body
    pdfData.tableData.forEach((e: TableData) => {
        const row: Row[] = [];
        row.push({
            text: e.number,
            italics: true,
            alignment: "center",
        });
        row.push({
            text: e.licenseHolder,
            italics: true,
            alignment: "center",
        });
        row.push({
            text: e.radiationWorkerName,
            italics: true,
            alignment: "center",
        });
        row.push({
            text: e.idCode,
            italics: true,
            alignment: "center",
        });
        row.push({
            text: e.category,
            italics: true,
            alignment: "center",
        });
        row.push({
            text: +e.annualExposure.toFixed(4),
            italics: true,
            alignment: "center",
        });
        tableBodyRows.push(row);
    });

    const table = [...tableHeader, ...tableBodyRows];

    const stack: Stack[] = []

    if (pdfData.userName || pdfData.companyName || pdfData.companyEmail) {
        stack.push({margin: [0, 30, 0, 0], text: ''}) // [left, top, right, bottom]
    }

    if (pdfData.userName) {
        stack.push({
            text: pdfData.userName,
        })
    }

    if (pdfData.companyName) {
        stack.push({
            text: pdfData.companyName,
        })
    }

    if (pdfData.companyEmail) {
        stack.push({
            text: pdfData.companyEmail,
        })
    }


    if (pdfData.date1 && pdfData.date2) {
        stack.push({
            margin: [0, 20, 0, 20],
            text: `Isikudooside mõõtetulemused perioodil: ${pdfData.date1} - ${pdfData.date2}`,
            alignment: "center",
        })
    } else if (pdfData.date1) {
        stack.push({
            margin: [0, 20, 0, 20],
            text: `Isikudooside mõõtetulemused alates: ${pdfData.date1}`,
            alignment: "center",
        })
    } else if (pdfData.date2) {
        stack.push({
            margin: [0, 20, 0, 20],
            text: `Isikudooside mõõtetulemused kuni: ${pdfData.date2}`,
            alignment: "center",
        })
    } else {
        stack.push({
            margin: [0, 20, 0, 0],
            text: ''
        })
    }


    if (pdfData.buildingName) {
        stack.push({
            text: `Asutuse nimi: ${pdfData.buildingName}`,
        })
    }

    if (pdfData.Address) {
        stack.push({
            text: `Kiirgustegevuse asukoht: ${pdfData.Address}`,
        })
    }

    return [
        {
            stack: [
                ...stack,
                {
                    margin: [0, 0, 0, 20], // [left, top, right, bottom]
                    text: `Mõõtmise meetod: ${pdfData.measurementMethod}`,
                },
                {
                    margin: [0, 10, 0, 10], // [left, top, right, bottom]
                    fontSize: fontSize,
                    layout: {
                        hLineWidth: () => {
                            return 0.5;
                        },
                        vLineWidth: () => {
                            return 0.5;
                        },
                        hLineColor: () => {
                            return "#000000";
                        },
                        vLineColor: () => {
                            return "#000000";
                        },
                    },
                    table: {
                        headerRows: 1,
                        widths: tableWidth,
                        body: table,
                    },
                },
            ],
        },
    ];
};
