
import {defineComponent, ref} from 'vue'
import ButtonAdd from "@/components/utils/buttons/ButtonAdd.vue";
import ClinicsReportsAddToWorkerModal
  from "@/components/clinics/reports/upload/worker/ClinicsReportsAddToWorkerModal.vue";

export default defineComponent({
  name: "ClinicsReportsAddToWorker",
  components: {ClinicsReportsAddToWorkerModal, ButtonAdd},
  setup() {
    const addWorkerReportsModal = ref(false)
    return {addWorkerReportsModal}
  }
})
