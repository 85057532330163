
import {computed, defineComponent, PropType, ref, watch, watchEffect} from 'vue'
import BaseTableBodyWrapper from "@/components/utils/tables/BaseTableBodyWrapper.vue";
import {useSnapshotsStore} from "@/store/snapshots/SnapshotsStore";
import ClinicReportsListItem from "@/components/clinics/reports/ClinicReportsListItem.vue";
import {ReportsFilterParams} from "@/components/clinics/reports/ClinicsReportsFilter.vue";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {PDFDataInput, SnapshotDTO} from "@/shared/types";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";


export default defineComponent({
  name: "ClinicReportsListBody",
  components: {ClinicReportsListItem, BaseTableBodyWrapper},
  props: {
    filterParams: {
      type: Object as PropType<ReportsFilterParams>,
      required: true
    },
    isDetailView: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    onWorkers: (workers: unknown) => Array.isArray(workers)
  },
  setup(props, {emit}) {
    const snapshotsStore = useSnapshotsStore()
    const snapshotsState = snapshotsStore.getState()
    const snapshots = computed(() => snapshotsState.snapshots)
    watchEffect(() => snapshots.value)

    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const clinics = computed(() => clinicsState.clinics)

    const cabinetsStore = useCabinetsStore()
    const cabinetsState = cabinetsStore.getState()
    const cabinets = computed(() => cabinetsState.cabinets)

    const clinicUID = computed(() => props.filterParams.clinicUID ? props.filterParams.clinicUID : props.filterParams.licenceNo ? clinics.value.find(c => c.licenseNos.includes(props.filterParams.licenceNo || ''))?.uid : undefined)
    const cabinetByUID = computed(() => props.filterParams.cabinetUID ? cabinets.value.find(cabinet => cabinet.uid === props.filterParams.cabinetUID) : undefined)
    const filteredCabinets = computed(() => {
      return cabinetByUID.value
          ? [cabinetByUID.value]
          : clinicUID.value
              ? cabinets.value.filter(cabinet => cabinet.clinicUID === clinicUID.value)
              : cabinets.value
    })
    const cabinetUIDs = computed(() => {
      return (props.filterParams.licenceNo
          ? filteredCabinets.value.filter(cabinet => cabinet.licenceNo === props.filterParams.licenceNo)
          : filteredCabinets.value)
          .map(cabinet => cabinet.uid)

    })

    const workerSnapshots = computed(() => {
      return snapshots.value.filter(snapshot => {
        if (!cabinetUIDs.value.includes(snapshot.cabinetUID)) return false
        if (props.filterParams.workerUID && !snapshot.workersUIDs.includes(props.filterParams.workerUID)) return false
        if (props.filterParams.dateFrom && snapshot.timestamp < props.filterParams.dateFrom) return false
        return !(props.filterParams.dateTo && snapshot.timestamp > props.filterParams.dateTo);
      }).reduce((acc, curr) => {
        curr.workersUIDs.forEach(workerUID => {
          if (props.filterParams.workerUID && workerUID !== props.filterParams.workerUID) return

          const filterImages = props.filterParams.images
          const pictures = filterImages?.length
              ? curr.pictures
                  .filter(p => p.value && !!filterImages.find(i => p.label.toLowerCase().includes(i.toLowerCase())))
              : curr.pictures
          if (!pictures.length) return;
          if (!acc[workerUID]) acc[workerUID] = []
          acc[workerUID].push({
            ...curr,
            pictures
          } as SnapshotDTO)
        })
        return acc
      }, {} as Record<string, SnapshotDTO[]>)
    })

    const workerUIDs = computed(() => Object.keys(workerSnapshots.value))

    const tableData = ref<PDFDataInput[]>([])
    const onWorker = (worker?: PDFDataInput) => {
      if (!worker) return
      tableData.value = [...tableData.value.filter(w => w.workerUID !== worker.workerUID), worker]
      emit('onWorkers', tableData.value.filter(w => workerUIDs.value.includes(w.workerUID)))
    }

    const picturesCounts = ref<{ grouped: Record<string, number>, total: number, workerUID: string }[]>([])
    const onPicturesCounts = (params: { counts: { grouped: Record<string, number>, total: number }, workerUID: string }) => {
      const {counts, workerUID} = params
      const workerIndex = picturesCounts.value.findIndex(item => item.workerUID === workerUID)
      if (workerIndex === -1) picturesCounts.value.push({...counts, workerUID})
      else picturesCounts.value.splice(workerIndex, 1, {...counts, workerUID})
    }

    const totals = computed(() => picturesCounts.value.reduce((acc, curr) => {
      const {grouped, total, workerUID} = curr
      if (!workerUIDs.value.includes(workerUID)) return acc
      for (const group of Object.keys(grouped)) {
        if (!acc[group]) acc[group] = grouped[group]
        else acc[group] += grouped[group]
      }
      acc.total += total
      return acc
    }, {total: 0} as Record<string, number>))


    return {workerSnapshots, workerUIDs, onWorker, onPicturesCounts, totals}
  }
})
