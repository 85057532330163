
import {computed, defineComponent, onBeforeMount, PropType, ref, watchEffect} from 'vue'
import BaseTableBodyWrapper from "@/components/utils/tables/BaseTableBodyWrapper.vue";
import {ReportCSVRow} from "@/components/clinics/reports/upload/fail/ClinicsReportsUpload.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {useXRayEquipmentsStore} from "@/store/xrayequipments/XRayEquipmentsStore";
import {useImagesStore} from "@/store/images/ImagesStore";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import {CabinetDto, ClinicDto, WorkerDto, XRayEquipmentDto, XRayImageDto} from "@/shared/types";
import ClinicsReportsUploadModalListItem
  from "@/components/clinics/reports/upload/fail/ClinicsReportsUploadModalListItem.vue";

export interface ClinicUploadListItem {
  readonly clinic: ClinicDto | undefined
  readonly cabinet: CabinetDto | undefined
  readonly xRayEquipment: XRayEquipmentDto | undefined
  readonly xRayImage: XRayImageDto | undefined
  readonly worker: WorkerDto | undefined
  readonly count: number | undefined
  readonly timestamp: number | undefined
  selected: boolean
}

export default defineComponent({
  name: "ClinicsReportsUploadModalListBody",
  components: {ClinicsReportsUploadModalListItem, BaseTableBodyWrapper},
  emits: {
    onRows: (rows: unknown) => Array.isArray(rows)
  },
  props: {
    items: {
      type: Array as PropType<Array<ReportCSVRow>>,
      required: true
    }
  },
  setup(props, {emit}) {
    const clinicsStore = useClinicsStore()
    const cabinetStore = useCabinetsStore()
    const equipmentsStore = useXRayEquipmentsStore()
    const imagesStore = useImagesStore()
    const workersStore = useWorkersStore()
    onBeforeMount(async () => await Promise.all([
      clinicsStore.setClinics(false),
      cabinetStore.setCabinets(false),
      equipmentsStore.setXRayEquipments(false),
      imagesStore.setImages(false),
      workersStore.setWorkers(false)
    ]))

    const clinicsState = clinicsStore.getState()
    const cabinetsState = cabinetStore.getState()
    const equipmentsState = equipmentsStore.getState()
    const imagesState = imagesStore.getState()
    const workersState = workersStore.getState()

    const clinics = computed(() => clinicsState.clinics)
    const cabinets = computed(() => cabinetsState.cabinets)
    const equipments = computed(() => equipmentsState.xRayEquipments)
    const images = computed(() => imagesState.images)
    const workers = computed(() => workersState.workers)


    const rows = ref<ClinicUploadListItem[]>([])

    const isValidRow = (row: ClinicUploadListItem): boolean => {
      const {clinic, cabinet, worker, xRayEquipment, xRayImage} = row
      if (!clinic || !cabinet || !worker || !xRayEquipment || !xRayImage) return false
      if (!clinic.cabinetsUID.includes(cabinet.uid)) return false
      if (!clinic.workersUID.includes(worker.uid)) return false
      if (!cabinet.xRayEquipmentsUID.includes(xRayEquipment.uid)) return false
      return xRayEquipment.imageUIDs.includes(xRayImage.uid);
    }

    watchEffect(() => rows.value = props.items.map(item => {
      const xRayEquipment = equipments.value.find(eq => eq.imageUIDs.includes(item.imageUID))
      const cabinet = xRayEquipment ? cabinets.value.find(cab => cab.xRayEquipmentsUID.includes(xRayEquipment.uid)) : undefined
      const clinic = cabinet ? clinics.value.find(clin => clin.cabinetsUID.includes(cabinet.uid)) : undefined
      const worker = workers.value.find(w => w.uid === item.workerUID)
      const image = images.value.find(i => i.uid === item.imageUID)
      const row = {
        clinic: clinic as ClinicDto,
        cabinet: cabinet as CabinetDto,
        xRayEquipment: xRayEquipment as XRayEquipmentDto,
        xRayImage: image as XRayImageDto,
        worker: worker as WorkerDto,
        timestamp: item.timestamp,
        count: item.count,
        selected: true
      }
      row.selected = isValidRow(row)
      return row
    }))
    const onChecked = (param: { checked: boolean, index: number }) => {
      const origRows = [...rows.value]
      origRows[param.index].selected = param.checked
      rows.value = origRows
    }

    watchEffect(() => emit('onRows', rows.value))

    return {rows, onChecked}
  }
})
