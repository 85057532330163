
import {computed, defineComponent, onBeforeMount, PropType, reactive, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import {PdfData} from "@/components/clinics/reports/pdfgenerator/pdf-parts/example-data";
import InputField from "@/components/utils/input/InputField.vue";
import {InputFieldType, Logo} from "@/types";
import pdfFunction from './pdfgenerator/index'
import AddImageForm from "@/components/forms/AddImageForm.vue";

export default defineComponent({
  name: "ClinicReportsPDFModal",
  components: {AddImageForm, InputField, ModalWrapper},
  emits: {
    onClose: () => true
  },
  props: {
    pdfData: {
      type: Object as PropType<PdfData>,
      required: true
    }
  },
  setup(props, {emit}) {
    const isOpen = ref(true)
    const onClose = () => emit('onClose')

    const logo = ref<string>('')
    const reportYear = ref<number>(0)
    const licenseNumber = ref<string>('')
    const userName = ref<string>('')
    const companyName = ref<string>('')
    const companyEmail = ref<string>('')
    const date1 = ref<string>('')
    const date2 = ref<string>('')
    const buildingName = ref<string>('')
    const Address = ref<string>('')
    const measurementMethod = ref<string>('')

    onBeforeMount(() => {
      logo.value = props.pdfData.logo
      reportYear.value = props.pdfData.reportYear
      licenseNumber.value = props.pdfData.licenseNumber
      userName.value = props.pdfData.userName
      companyName.value = props.pdfData.companyName
      companyEmail.value = props.pdfData.companyEmail
      date1.value = props.pdfData.date1
      date2.value = props.pdfData.date2
      buildingName.value = props.pdfData.buildingName
      Address.value = props.pdfData.Address
      measurementMethod.value = props.pdfData.measurementMethod
    })

    const pdfInput = computed<PdfData>(() => ({
      logo: logo.value,
      reportYear: reportYear.value,
      licenseNumber: licenseNumber.value,
      userName: userName.value,
      companyName: companyName.value,
      companyEmail: companyEmail.value,
      date1: date1.value,
      date2: date2.value,
      buildingName: buildingName.value,
      Address: Address.value,
      measurementMethod: measurementMethod.value,
      tableData: props.pdfData.tableData
    }))

    const loadPDF = async () => {
      await pdfFunction(pdfInput.value)
      isOpen.value = false
    }

    const onLogo = (payload: Logo) => {
      const reader = new FileReader();
      reader.onload = (e) => (logo.value = e.target?.result as string);
      reader.readAsDataURL(payload.meta);
    };

    return {
      loadPDF,
      isOpen,
      onClose,
      InputFieldType,
      logo,
      reportYear,
      licenseNumber,
      userName,
      companyName,
      companyEmail,
      date1,
      date2,
      buildingName,
      Address,
      measurementMethod,
      onLogo
    }
  }
})
