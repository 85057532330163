import * as pdfMake from "pdfmake/build/pdfmake";
import {TDocumentDefinitions} from "pdfmake/interfaces";

import customFonts from "./pdf-parts/fonts";
// eslint-disable-next-line
(<any>pdfMake).vfs = customFonts;

// import data
import {PdfData} from "./pdf-parts/example-data";

// import document parts
import {pdfHeader} from "./pdf-parts/header";
import {pdfFooter} from "./pdf-parts/footer";
import {pdfContent} from "./pdf-parts/content";
import {pdfStyle} from "./pdf-parts/style";

type Margins = number | [number, number] | [number, number, number, number];

const fonts = {
    Saira: {
        normal: "Saira-Medium.ttf",
        italics: "Saira-Light.ttf",
    },
    Buenard: {
        normal: "Buenard-Regular.ttf",
    },
};

export default async (pdfData: PdfData): Promise<void> => {
    const docDefinition = {
        header: pdfHeader(pdfData),
        content: pdfContent(pdfData),
        footer: (currentPage: any, pageCount: any) => {
            return pdfFooter(currentPage, pageCount);
        },
        styles: pdfStyle(),
        pageMargins: [20, 60, 20, 110] as Margins, // [left, top, right, bottom]
        defaultStyle: {
            font: "Saira",
        },
    };

   await pdfMake
        .createPdf((docDefinition as unknown) as TDocumentDefinitions, {}, fonts)
        .download(`Kiirgusseire aruanne${pdfData.licenseNumber ? ' _' + pdfData.licenseNumber : ''}`);
};
