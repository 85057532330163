
import {computed, defineComponent, PropType} from 'vue'
import {ClinicUploadListItem} from "@/components/clinics/reports/upload/fail/ClinicsReportsUploadModalListBody.vue";

export default defineComponent({
  name: "ClinicsReportsUploadModalListItem",
  emits: {
    onChecked: (checked: unknown) => typeof checked === 'boolean'
  },
  props: {
    item: {
      type: Object as PropType<ClinicUploadListItem>,
      required: true
    }
  },
  setup(props, {emit}) {
    const formatedDate = computed(() => {
      if (!props.item.timestamp) return
      const selectedDate = new Date(props.item.timestamp)
      const day = selectedDate.getDate();
      const month = selectedDate.getMonth() + 1;
      const year = selectedDate.getFullYear();
      return `${day < 10 ? "0" + day.toString() : day}.${month < 10 ? "0" + month.toString() : month}.${year}`;
    })

    const isValid = computed(() => {
      const {clinic, cabinet, worker, xRayEquipment, xRayImage, count} = props.item
      if (!clinic || !cabinet || !worker || !xRayEquipment || !xRayImage) return false
      if (!clinic.cabinetsUID.includes(cabinet.uid)) return false
      if (!clinic.workersUID.includes(worker.uid)) return false
      if (!cabinet.xRayEquipmentsUID.includes(xRayEquipment.uid)) return false
      if (!count) return false
      return xRayEquipment.imageUIDs.includes(xRayImage.uid);

    })

    const onValidChange = (e: { target: HTMLInputElement }) => emit('onChecked', e.target.checked)
    return {formatedDate, isValid, onValidChange}
  }
})
