
import {computed, defineComponent, onMounted, ref} from 'vue'
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import BaseTableBodyWrapper from "@/components/utils/tables/BaseTableBodyWrapper.vue";
import InputField from "@/components/utils/input/InputField.vue";
import BaseTableWrapper from "@/components/utils/tables/BaseTableWrapper.vue";
import SelectWorkerItem from "@/components/clinics/reports/upload/worker/SelectWorkerItem.vue";


export default defineComponent({
  name: "SelectWorker",
  components: {SelectWorkerItem, BaseTableWrapper, InputField, BaseTableBodyWrapper},
  emits: {
    onWorker: (worker: unknown) => !!worker
  },

  setup(_, {emit}) {


    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const activeClinicUID = computed(() => clinicsState.activeClinicUID)
    const workersStore = useWorkersStore()
    const workersState = workersStore.getState()

    onMounted(async () => {
      await Promise.all([workersStore.setWorkers(false)]);
    });

    const allWorkers = computed(() => workersState.workers)
    const workers = computed(() => activeClinicUID.value ? allWorkers.value.filter(worker => worker.clinicUID === activeClinicUID.value) : allWorkers.value)
    const nameSearchInput = ref<string | undefined>(undefined)
    const filteredWorkers = computed(() => nameSearchInput.value ? workers.value.filter(w => w.name.toLowerCase().includes(nameSearchInput.value!.toLowerCase())) : workers.value)
    const onWorker = (workerUID: string) => emit('onWorker', filteredWorkers.value.find(w => w.uid === workerUID))
    return {filteredWorkers, nameSearchInput, onWorker}
  }
})
