<template>
  <thead class="bg-gray-50">
  <tr>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

    </th>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Kliinik
    </th>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Nimi
    </th>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Kabinet
    </th>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Seade
    </th>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Ülesvõte
    </th>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Kogus
    </th>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Kuupäev
    </th>
    <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

    </th>
  </tr>
  </thead>
</template>
