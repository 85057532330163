
import {computed, defineComponent, PropType} from 'vue'
import {WorkerDto} from "@/shared/types";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export default defineComponent({
  name: "SelectWorkerItem",
  props: {
    worker: {
      type: Object as PropType<WorkerDto>,
      required: true
    }
  },
  setup(props) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const clinic = computed(() => clinicsState.clinics.find((clinic) => props.worker.clinicUID === clinic.uid))
    return {clinic}
  }
})
