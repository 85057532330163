
import {computed, defineComponent, PropType, ref, watch, watchEffect} from 'vue'

import {useWorkersStore} from "@/store/workers/WorkersStore";
import {PDFDataInput, SnapshotDTO} from "@/shared/types";
import {useSnapshotsStore} from "@/store/snapshots/SnapshotsStore";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";


export default defineComponent({
  name: "ClinicReportsListItem",
  props: {
    workerUID: {
      type: String,
      required: true
    },
    snapShots: {
      type: Array as PropType<SnapshotDTO[]>,
      required: true
    },
    isDetailView: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    onWorker: (worker: unknown) => !!worker || typeof worker === "undefined",
    onPicturesCounts: (counts: unknown) => !!counts
  },
  setup(props, {emit}) {
    const workersStore = useWorkersStore()
    const workersState = workersStore.getState()
    const worker = computed(() =>
        workersState.workers.find(worker => worker.uid === props.workerUID)
        ?? workersState.pastWorkers.find(worker => worker.uid === props.workerUID
        ))
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const clinic = computed(() => {
      if (!worker.value) return undefined
      return clinicsState.clinics.find(clinic => clinic.uid === worker.value?.clinicUID)
    })


    const snapshotsStore = useSnapshotsStore()
    const snapshotsState = snapshotsStore.getState()

    const images = computed(() => snapshotsState.images)
    const dose = computed(() => props.snapShots.reduce((acc, curr) => {
      acc += curr.pictures.map(picture => {
        const image = images.value.find(im => im.uid === picture.xRayImageId)
        if (!image) return 0
        else return image.secondsRate / 3600 * image.doseRate * picture.value
      }).reduce((acc, curr) => acc + curr, 0)
      return acc
    }, 0))

    const output = computed<PDFDataInput | undefined>(() => {
      if (!worker.value || !clinic.value) return
      return {
        workerUID: worker.value.uid,
        licenseHolder: clinic.value.radiationPracticeLicenseHolder,
        radiationWorkerName: worker.value.name,
        idCode: worker.value.personalNo,
        category: worker.value.radiationWorkerCategory,
        annualExposure: dose.value
      }
    })


    const picturesGroupedCount = computed(() => {
      return props.snapShots.reduce((acc, curr) => {
        for (const picture of curr.pictures) {
          const labelSplit = picture.label.split('- ')
          const label = labelSplit[labelSplit.length - 1]
          if (!acc[label]) acc[label] = picture.value
          else acc[label] += picture.value
        }
        return acc
      }, {} as Record<string, number>)
    })


    const picturesCount = computed(() => props.snapShots.reduce((acc, curr) => acc + curr.pictures.reduce((acc2, curr2) => acc2 + curr2.value, 0), 0))
    watch(() => output.value, (newValue) => emit('onWorker', newValue))

    watchEffect(() => {
      if (!props.isDetailView) return
      emit('onPicturesCounts', {
        grouped: picturesGroupedCount.value,
        total: picturesCount.value
      })
    })
    return {worker, dose, clinic, picturesCount, picturesGroupedCount}
  }
})
