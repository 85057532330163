
import {computed, defineComponent, ref, watch, watchEffect} from "vue";
import CabinetsDropdown from "@/components/clinics/cabinets/utils/CabinetsDropdown.vue";
import WorkersDropdown from "@/components/clinics/workers/utils/WorkersDropdown.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import MDatePicker from "@/components/utils/input/mdatepicker/MDatePicker.vue";
import LicenceNosDropdown from "@/components/clinics/addclinic/utils/LicenceNosDropdown.vue";
import XRayImagesDropdown from "@/components/xrayequipments/XRayImagesDropdown.vue";

export interface ReportsFilterParams {
  clinicUID?: string
  cabinetUID?: string
  workerUID?: string
  licenceNo?: string
  images?: string[]
  dateFrom?: number
  dateTo?: number
}

export default defineComponent({
  name: 'ClinicsReportsFilter',
  components: {
    XRayImagesDropdown,
    LicenceNosDropdown,
    MDatePicker,
    WorkersDropdown,
    CabinetsDropdown
  },
  emits: {
    onFilter: (filterParams: unknown) => !!filterParams,
    onDetailView: (state: unknown) => typeof state === "boolean"
  },
  setup(_, {emit}) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const activeClinicUID = computed(() => clinicsState.activeClinicUID)

    const isDetailView = ref(false)
    watchEffect(() => emit('onDetailView', isDetailView.value))

    const initialDateTo = computed(() => {
      const date = new Date()
      date.setHours(24, 0, 0, 0)
      return date
    })
    const initialDateFrom = computed(() => {
      const date = new Date(initialDateTo.value.getFullYear(), 0, 1)
      date.setHours(0, 0, 0, 0)
      return date
    })

    const resetToggle = ref(false)

    const clinicUID = ref<string | undefined>(undefined)
    const cabinetUID = ref<string | undefined>(undefined)
    const workerUID = ref<string | undefined>(undefined)
    const licenceNo = ref<string | undefined>(undefined)
    const dateFrom = ref<number | undefined>(undefined)
    const dateTo = ref<number | undefined>(undefined)
    const images = ref<string[] | undefined>(undefined)


    const params = computed<ReportsFilterParams>(() => ({
      clinicUID: activeClinicUID.value,
      cabinetUID: cabinetUID.value,
      workerUID: workerUID.value,
      licenceNo: licenceNo.value,
      dateFrom: dateFrom.value,
      dateTo: dateTo.value,
      images: images.value
    }))


    watch(
        () => activeClinicUID.value,
        () => {
          clinicUID.value = activeClinicUID.value
          cabinetUID.value = undefined
          workerUID.value = undefined
          licenceNo.value = undefined
          emit('onFilter', params.value)
        })

    const onCabinet = (uid: string) => {
      cabinetUID.value = uid
      emit('onFilter', params.value)
    }
    const onWorker = (uid: string) => {
      workerUID.value = uid
      emit('onFilter', params.value)
    }

    const onLicenceNo = (newLicenceNo: string) => {
      licenceNo.value = newLicenceNo
      emit('onFilter', params.value)
    }

    const onImages = (payload: string[]) => {
      images.value = payload.length ? payload : undefined
      emit('onFilter', params.value)
    }


    const setDateFrom = (date?: Date) => {
      date?.setHours(0, 0, 0, 0)
      dateFrom.value = date ? date.getTime() : undefined
      emit('onFilter', params.value)
    }
    const setDateTo = (date?: Date) => {
      date?.setHours(23, 59, 59)
      dateTo.value = date ? date.getTime() : undefined
      emit('onFilter', params.value)
    }

    const clearFilters = () => resetToggle.value = !resetToggle.value


    return {
      activeClinicUID,
      setDateFrom,
      setDateTo,
      onCabinet,
      onWorker,
      clearFilters,
      resetToggle,
      onLicenceNo,
      initialDateTo,
      initialDateFrom,
      licenceNo,
      cabinetUID,
      onImages,
      isDetailView
    }
  }
})
