
import {computed, defineComponent, PropType} from "vue";
import {PdfData} from "@/components/clinics/reports/pdfgenerator/pdf-parts/example-data";

export default defineComponent({
  name: "ClinicReportsHeader",
  props: {
    data: {
      type: Object as PropType<PdfData>,
      required: true,
    },
    isDetailView: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const dateRangeTitle = computed(() => {
      if (props.data.date1 && props.data.date2) {
        return `Isikudooside mõõtetulemused perioodil: ${props.data.date1} - ${props.data.date2}`;
      } else if (props.data.date1) {
        return `Isikudooside mõõtetulemused alates: ${props.data.date1}`;
      } else if (props.data.date2) {
        return `Isikudooside mõõtetulemused kuni: ${props.data.date2}`;
      }
      return undefined;
    });

    const detailViewTitle = computed(() => {
      if (props.data.date1 && props.data.date2) {
        return `Detailne statistika perioodil: ${props.data.date1} - ${props.data.date2}`;
      } else if (props.data.date1) {
        return `Detailne statistika alates: ${props.data.date1}`;
      } else if (props.data.date2) {
        return `Detailne statistika kuni: ${props.data.date2}`;
      }
      return undefined;
    })
    return {dateRangeTitle, detailViewTitle};
  },
});
