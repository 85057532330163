
import {defineComponent, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import SelectWorker from "@/components/clinics/reports/upload/worker/SelectWorker.vue";
import {WorkerDto} from "@/shared/types";
import FillImages from "@/components/clinics/reports/upload/worker/FillImages.vue";

enum AddReportsToWorkerStep {
  SELECT_WORKER = 'SELECT_WORKER',
  FILL_IMAGES = 'FILL_IMAGES'
}

export default defineComponent({
  name: "ClinicsReportsAddToWorkerModal",
  components: {FillImages, SelectWorker, ModalWrapper},
  emits: {
    onClose: () => true
  },
  setup(_, {emit}) {
    const isOpen = ref(true)
    const onClose = () => emit('onClose')

    const step = ref<AddReportsToWorkerStep>(AddReportsToWorkerStep.SELECT_WORKER)
    const selectedWorker = ref<WorkerDto | undefined>(undefined)
    const onWorker = (worker: WorkerDto) => {
      selectedWorker.value = worker
      step.value = AddReportsToWorkerStep.FILL_IMAGES
    }

    return {isOpen, onClose, step, AddReportsToWorkerStep, onWorker, selectedWorker}
  }
})
