
import {defineComponent} from 'vue'
import ClinicsReportsAddToWorker from "@/components/clinics/reports/upload/worker/ClinicsReportsAddToWorker.vue";
import ClinicsReportsUpload from "@/components/clinics/reports/upload/fail/ClinicsReportsUpload.vue";

export default defineComponent({
  name: "ReportsUpload",
  components: {ClinicsReportsUpload, ClinicsReportsAddToWorker},
  setup() {
    return {}
  }
})
