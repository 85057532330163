
import {defineComponent, PropType} from 'vue'

import ClinicRepostsListHeader from "@/components/clinics/reports/ClinicRepostsListHeader.vue";
import BaseTableWrapper from "@/components/utils/tables/BaseTableWrapper.vue";
import ClinicReportsListBody from "@/components/clinics/reports/ClinicReportsListBody.vue";
import {ReportsFilterParams} from "@/components/clinics/reports/ClinicsReportsFilter.vue";
import {PDFDataInput} from "@/shared/types";


export default defineComponent({
  name: "ClinicReportsList",
  components: { ClinicReportsListBody, BaseTableWrapper, ClinicRepostsListHeader},
  props: {
    filterParams: {
      type: Object as PropType<ReportsFilterParams>,
      required: true
    },
    isDetailView: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    onWorkers: (workers: unknown) => Array.isArray(workers)
  },
  setup(_, {emit}) {
    const onWorkers = (workers: PDFDataInput[]) => emit('onWorkers', workers)
    return {onWorkers}
  }
})
