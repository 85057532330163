
import {computed, defineComponent, onMounted, ref, watch, watchEffect} from 'vue'
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";

export default defineComponent({
  name: "XRayImagesDropdown",
  components: {MSimpleDropdownOption, MSimpleDropdownButton, MSimpleDropdown},
  emits: {
    onImage: (name: unknown) => typeof name === 'string',
    onImages: (images: unknown) => Array.isArray(images)
  },
  props: {
    imageName: {
      type: String,
      required: false
    },
    resetSelectedToggle: {
      type: Boolean,
      required: false
    },
    showSelectAll: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const isDropdown = ref(false)
    const setIsDropdown = (newValue: boolean) => isDropdown.value = newValue
    const selected = ref<string[]>([])
    const isImageSelected = (image: string) => selected.value.includes(image)
    const addOption = (image: string) => props.multiSelect ? selected.value.push(image) : selected.value = [image]
    const removeOption = (image: string) => selected.value = selected.value.filter(v => v !== image)
    const handleMultiselect = (image: string) => isImageSelected(image) ? removeOption(image) : addOption(image)
    const handleSingleSelect = (image: string) => {
      selected.value = [image]
      setIsDropdown(false)
    }

    const clearOptions = () => selected.value = []
    const toggleOption = (image: string) => props.multiSelect ? handleMultiselect(image) : handleSingleSelect(image)
    const haveSomeSelected = computed(() => !!selected.value.length)

    watchEffect(() => props.imageName ? toggleOption(props.imageName) : clearOptions())
    watchEffect(() => props.multiSelect ? emit('onImages', selected.value) : selected.value.length ? emit('onImage', selected.value[0]) : undefined)


    const dropdownID = 'x-ray-image-dd'
    const buttonText = computed(() => haveSomeSelected.value ? selected.value.join(', ') : '')
    const title = computed(() => haveSomeSelected.value ? 'Ülesvõtte liik' : 'Vali ülesvõtte liik')
    const images = [
      'Intraoraalne',
      'OPTG',
      '3D sektorist',
      '3D lõualuust',
      '3D lõualuudest',
      'CEPH',
    ]

    onMounted(() => {
      window.addEventListener('click', (e: MouseEvent) => {
        if (isDropdown.value) {
          const target = e.target as HTMLDivElement
          if (!target.closest(`#${dropdownID}`)) setIsDropdown(false)
        }
      })
    })


    const selectAll = () => clearOptions()
    watch(() => props.resetSelectedToggle, () => clearOptions())
    return {
      isDropdown,
      dropdownID,
      setIsDropdown,
      selected,
      title,
      images,
      buttonText,
      toggleOption,
      selectAll,
      isImageSelected,
      haveSomeSelected
    }
  }
})
