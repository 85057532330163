import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicsReportsAddToWorker = _resolveComponent("ClinicsReportsAddToWorker")!
  const _component_ClinicsReportsUpload = _resolveComponent("ClinicsReportsUpload")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ClinicsReportsAddToWorker, { class: "mx-4" }),
    _createVNode(_component_ClinicsReportsUpload, { class: "mr-6" })
  ]))
}