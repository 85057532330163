
import {defineComponent, PropType} from 'vue'
import {ReportCSVRow} from "@/components/clinics/reports/upload/fail/ClinicsReportsUpload.vue";
import BaseTableBodyWrapper from "@/components/utils/tables/BaseTableBodyWrapper.vue";
import ClinicsReportsUploadModalListHeader
  from "@/components/clinics/reports/upload/fail/ClinicsReportsUploadModalListHeader.vue";
import ClinicsReportsUploadModalListBody
  , {ClinicUploadListItem} from "@/components/clinics/reports/upload/fail/ClinicsReportsUploadModalListBody.vue";


export default defineComponent({
  name: "ClinicsReportsUploadModalList",
  components: {ClinicsReportsUploadModalListBody, ClinicsReportsUploadModalListHeader, BaseTableBodyWrapper},
  emits: {
    onRows: (rows: unknown) => Array.isArray(rows)
  },
  props: {
    items: {
      type: Array as PropType<Array<ReportCSVRow>>,
      required: true
    }
  },
  setup(_, {emit}) {
    const onRows = (rows: ClinicUploadListItem[]) => emit('onRows', rows)
    return {onRows}
  }
})
