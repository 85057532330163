import {logo} from "@/components/clinics/reports/pdfgenerator/pdf-parts/logo";

export const pdfFooter = (currentPage: any, pageCount: any) => {
  return {
    stack: [
      {
        margin: pageCount > 1 ? [0, 5, 20, 0] : [0, 0, 20, 0], // [left, top, right, bottom]
        text: pageCount > 1 ? `lk ${currentPage}/${pageCount}` : "",
        alignment: "right",
      },
      {
        margin: pageCount > 1 ? [0, 10, 0, 10] : [0, 30, 0, 10], // [left, top, right, bottom]
        width: 595,
        svg:
          '<svg height="1" width="515"> <line x1="0" y1="0" x2="800" y2="0" style="stroke:rgb(0,0,0);stroke-width:2" /> </svg>',
      },
      {
        margin: [20, 0, 20, 20], // [left, top, right, bottom]
        columns: [
          {
            margin: [0, 20, 0, 0], // [left, top, right, bottom]
            width: "90%",
            text: "Report generated by Radisol",
            alignment: "left",
            color: "gray",
          },
          {
            width: "10%",
            stack: [
              {
                margin: [0, 5, 0, 0], // [left, top, right, bottom]
                image: logo,
                width: 50,
              },
            ],
          },
        ],
      },
    ],
  };
};
