
import {defineComponent, PropType, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import {ReportCSVRow} from "@/components/clinics/reports/upload/fail/ClinicsReportsUpload.vue";
import ClinicsReportsUploadModalList from "@/components/clinics/reports/upload/fail/ClinicsReportsUploadModalList.vue";
import {ClinicUploadListItem} from "@/components/clinics/reports/upload/fail/ClinicsReportsUploadModalListBody.vue";
import {AddSnapshotInput} from '@/shared/types';
import {useSnapshotsStore} from "@/store/snapshots/SnapshotsStore";

export default defineComponent({
  name: "ClinicsReportsUploadModal",
  components: {ClinicsReportsUploadModalList, ModalWrapper},
  emits: {
    onClose: () => true
  },
  props: {
    items: {
      type: Array as PropType<Array<ReportCSVRow>>,
      required: true
    }
  },
  setup(_, {emit}) {
    const snapshotsStore = useSnapshotsStore()

    const isOpen = ref(true)
    const onClose = () => emit('onClose')

    const snapshots = ref<AddSnapshotInput[]>([])
    const onRows = (rows: ClinicUploadListItem[]) => {
      const validRows = rows.filter(row => row.selected && !!row.cabinet && !!row.worker && !!row.xRayEquipment && !!row.xRayImage && !!row.timestamp && !!row.count)
      const results: AddSnapshotInput[] = []
      for (const row of validRows) {
        for (const _ of Array(row.count).fill(undefined)) {
          results.push({
            cabinetUID: row.cabinet!.uid,
            timestamp: row.timestamp!,
            workersUIDs: [row.worker!.uid],
            pictures: [
              {
                label: `${row.xRayEquipment!.name} - ${row.xRayImage!.imageName}`,
                value: 1,
                xRayEquipmentUID: row.xRayEquipment!.uid,
                xRayImageId: row.xRayImage!.uid
              }
            ]
          })
        }
      }
      snapshots.value = results
    }

    const addSnapshots = async () => {
      if (snapshots.value.length) {
        await snapshotsStore.addSnapshots(snapshots.value)
        isOpen.value = false
      }
    }
    return {isOpen, onClose, onRows, addSnapshots}
  }
})
