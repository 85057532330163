import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2 w-full flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicsReportsUploadModalList = _resolveComponent("ClinicsReportsUploadModalList")!
  const _component_ModalWrapper = _resolveComponent("ModalWrapper")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-wrapper" }, [
    _createVNode(_component_ModalWrapper, {
      open: _ctx.isOpen,
      onOnclose: _ctx.onClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ClinicsReportsUploadModalList, {
          items: _ctx.items,
          onOnRows: _ctx.onRows
        }, null, 8, ["items", "onOnRows"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addSnapshots && _ctx.addSnapshots(...args))),
            class: "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          }, " Salvesta ")
        ])
      ]),
      _: 1
    }, 8, ["open", "onOnclose"])
  ]))
}