import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "transition duration-100 ease-in-out hover:bg-indigo-100 group" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_5 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_6 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 border-r transition duration-100 ease-in-out group-hover:border-indigo-200" }
const _hoisted_7 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 border-r transition duration-100 ease-in-out group-hover:border-indigo-200" }
const _hoisted_8 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 border-r transition duration-100 ease-in-out group-hover:border-indigo-200" }
const _hoisted_9 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 border-r transition duration-100 ease-in-out group-hover:border-indigo-200" }
const _hoisted_10 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 border-r transition duration-100 ease-in-out group-hover:border-indigo-200" }
const _hoisted_11 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 border-r transition duration-100 ease-in-out group-hover:border-indigo-200" }
const _hoisted_12 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 border-r transition duration-100 ease-in-out group-hover:border-indigo-200" }
const _hoisted_13 = { class: "px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-700 border-r transition duration-100 ease-in-out group-hover:border-indigo-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    (!_ctx.isDetailView)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.clinic?.radiationPracticeLicenseHolder), 1),
          _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.worker?.name), 1),
          _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.worker?.radiationWorkerCategory), 1),
          _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.dose.toFixed(4)), 1)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.worker?.name), 1),
          _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.picturesGroupedCount['Intraoraalne'] || ''), 1),
          _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.picturesGroupedCount['OPTG'] || ''), 1),
          _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.picturesGroupedCount['3D sektorist'] || ''), 1),
          _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.picturesGroupedCount['3D lõualuust'] || ''), 1),
          _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.picturesGroupedCount['3D lõualuudest'] || ''), 1),
          _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.picturesGroupedCount['CEPH'] || ''), 1),
          _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.picturesCount), 1)
        ], 64))
  ]))
}