
import {computed, defineComponent, onBeforeMount, PropType, ref} from 'vue'
import {AddSnapshotInput, WorkerDto} from "@/shared/types";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {useXRayEquipmentsStore} from "@/store/xrayequipments/XRayEquipmentsStore";
import {useImagesStore} from "@/store/images/ImagesStore";
import BaseTableWrapper from "@/components/utils/tables/BaseTableWrapper.vue";
import InputField from "@/components/utils/input/InputField.vue";
import BaseTableBodyWrapper from "@/components/utils/tables/BaseTableBodyWrapper.vue";
import {InputFieldType} from "@/types";
import {useSnapshotsStore} from "@/store/snapshots/SnapshotsStore";
import MDatePicker from "@/components/utils/input/mdatepicker/MDatePicker.vue";

export default defineComponent({
  name: "FillImages",
  components: {MDatePicker, BaseTableBodyWrapper, InputField, BaseTableWrapper},
  props: {
    worker: {
      type: Object as PropType<WorkerDto>,
      required: true
    }
  },
  emits: {
    onAdded: () => true
  },
  setup(props, {emit}) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()

    const cabinetsStore = useCabinetsStore()
    const cabinetsState = cabinetsStore.getState()
    const cabinets = computed(() => cabinetsState.cabinets)

    const xRayEquipmentsStore = useXRayEquipmentsStore()
    const xRayEquipmentsState = xRayEquipmentsStore.getState()
    const equipments = computed(() => xRayEquipmentsState.xRayEquipments)

    const imagesStore = useImagesStore()
    const imagesState = imagesStore.getState()

    const snapshotsStore = useSnapshotsStore()

    const imageCounts = ref<{ uid: string, count: number }[]>([])

    const timestamp = ref<number>(new Date().getTime())

    onBeforeMount(async () => {
      await Promise.all([clinicsStore.setClinics(false), cabinetsStore.setCabinets(false), xRayEquipmentsStore.setXRayEquipments(false), imagesStore.setImages(false)])
    })

    const clinic = computed(() => clinicsState.clinics.find((clinic) => props.worker.clinicUID === clinic.uid))

    const cabinetName = ref<string | undefined>(undefined)
    const equipmentName = ref<string | undefined>(undefined)
    const imageName = ref<string | undefined>(undefined)


    const images = computed(() => imagesState.images.map(image => {
      const equipment = equipments.value.find(eq => eq.imageUIDs.includes(image.uid))
      const cabinet = equipment ? cabinets.value.find(cab => cab.xRayEquipmentsUID.includes(equipment.uid)) : undefined
      return {
        clinicUID: cabinet?.clinicUID || '',
        cabinetUID: cabinet?.uid || '',
        cabinetName: cabinet?.name || '',
        equipmentName: equipment?.name || '',
        equipmentUID: equipment?.uid || '',
        imageUID: image.uid,
        imageName: image.imageName,
      }
    }).filter(i => {
      if (clinic.value && i.clinicUID !== clinic.value.uid) return false
      if (cabinetName.value && !i.cabinetName.toLowerCase().includes(cabinetName.value!.toLowerCase())) return false
      if (equipmentName.value && !i.equipmentName.toLowerCase().includes(equipmentName.value!.toLowerCase())) return false
      return !(imageName.value && !i.imageName.toLowerCase().includes(imageName.value!.toLowerCase()));

    }))

    const onCount = (param: { uid: string, count: number }) => {
      imageCounts.value = [...imageCounts.value.filter(i => i.uid !== param.uid)]
      imageCounts.value.push(param)
    }

    const onDate = (date: Date) => timestamp.value = date.getTime()

    const getImageCount = (uid: string) => imageCounts.value.find(i => i.uid === uid)?.count || 0

    const onSave = async () => {
      const snapshots: AddSnapshotInput[] = images.value.filter(i => getImageCount(i.imageUID) > 0).map(i => ({
        cabinetUID: i.cabinetUID,
        timestamp: timestamp.value,
        workersUIDs: [props.worker.uid],
        pictures: [{
          label: `${i.equipmentName} - ${i.imageName}`,
          value: getImageCount(i.imageUID),
          xRayEquipmentUID: i.equipmentUID,
          xRayImageId: i.imageUID
        }]
      }))
      if (snapshots.length) {
        await snapshotsStore.addSnapshots(snapshots)
        imageCounts.value = []
        emit('onAdded')
      }
    }


    return {
      clinic,
      cabinetName,
      equipmentName,
      imageName,
      images,
      onSave,
      InputFieldType,
      onCount,
      getImageCount,
      timestamp,
      onDate
    }
  }
})
